.app {
    position: relative;
}

.container {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;

    /* Clear default scrollbar */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
}

.container::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Edge */
}

.section {
    scroll-snap-align: start;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: white;
}

.section1 {
    background-color: #3498db;
}

.section2 {
    background-color: #e74c3c;
}

.section3 {
    background-color: #2ecc71;
}

/* Custom Scrollbar */
.custom-scrollbar {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 8px;
}

.scroll-thumb {
    position: absolute;
    width: 8px;
    background: black;
    border-radius: 4px;
    transition: top 0.3s ease;
}

/* Dot Indicator Styling */
.indicator {
    position: fixed;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ccc;
    transition: background 0.3s ease;
}

.dot.active {
    background: #000;
}