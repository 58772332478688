body {
    margin: 0;
    padding: 0 0vw;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    background-color: #ffffff;
}

.logo {
    height: 70px;
}

.main-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #111111;
    padding: 1rem 3rem;
    position: relative;

    @media only screen and (max-width:704px) {
        flex-direction: column;
        padding: 1rem;
    }
}

.nav-links {
    @media only screen and (max-width: 704px) {
        text-align: center;
        padding: 2rem 0;
    }

    a {
        text-decoration: none;
        transition: .2s;
        color: white;
        margin-left: 2rem;

        @media only screen and(max-width: 704px) {
            display: block;
            margin: 2px auto;
        }

        &:hover {
            color: #3fc1c9;
        }
    }
}

.hamburger-react {
    display: none;

    @media only screen and (max-width: 704px) {
        display: block;
    }
}

.project-preview {
    width: 50vw;
}

.home {
    color: #ffffff;

    a {
        color: #3fc1c9;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.parent-main {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    h2 {
        grid-area: 1 / 1 / 2 / 3;
    }
}

.parent-sides {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 90%;
    margin-top: -5%;

    .Left {
        grid-area: 1 / 1 / 2 / 3;
    }

    .Right {
        grid-area: 1 / 3 / 2 / 4;
    }
}

.parent-center {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 90%;
    margin-top: -5%;

    .Center {
        text-align: center;
        grid-area: 1 / 2 / 5 / 5;
    }
}

.text-black {
    color: black;
}